import { get } from "storefront/GrailedAPI/request";
import { Id } from "storefront/lib/Id";
import { Options } from "storefront/GrailedAPI/request/Options";
import Fetcher from "storefront/GrailedAPI/Fetcher";

import type { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";

type Params = {
  keys: Feature[];
  userId?: Id;
};

export type FlipperStates = Record<string, { enabled: boolean }>;

type Response = {
  data: FlipperStates;
};

const unpack = (res: Response): FlipperStates => res.data;

const getFeatures = (params: Params, options?: Options) => {
  if (typeof window === "undefined" && !options?.headers) {
    throw new Error("Flipper fetcher requires headers in server-side requests");
  }

  const paramsWithKeys = {
    ...params,
    keys: params.keys.join(","),
  };

  return get<Response>("/api/flipper", paramsWithKeys, options);
};

const getFeaturesFetcher = new Fetcher(unpack, getFeatures);

export default getFeaturesFetcher.call;
