import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import Script from "next/script";
import { useRouter } from "next/router";
import { createWrapper } from "next-redux-wrapper";
import useSWR from "swr";
import getUnreadCounts from "storefront/GrailedAPI/v1/Conversations/getUnreadCounts";
import { underscorify } from "camelscore";
import "@stripe/stripe-js";
import { Provider as AuthenticationProvider } from "storefront/hooks/useAuthentication";
import { Provider as AnalyticsProvider } from "storefront/hooks/useAnalytics";
import { PhoneVerificationProvider } from "storefront/components/PhoneVerification/Context";
import { requestWatcher } from "storefront/GrailedAPI/request/request";
import FlashManager from "storefront/lib/flash/FlashManager";
import { PublicConfig } from "storefront/Config/PublicConfig";
import { key as PUBLIC_CONFIG_KEY } from "storefront/hooks/usePublicConfig";
import getCategories, {
  ResponseData as CategoryResponseData,
  CACHE_KEY as CATEGORIES_RESPONSE_CACHE_KEY,
} from "storefront/GrailedAPI/v1/Config/getCategories";
import getConfig from "storefront/GrailedAPI/v1/Config/getConfig";
import InitialDataProvider from "storefront/InitialDataProvider";
import getFeatures, {
  FlipperStates,
} from "storefront/GrailedAPI/v1/Users/Flipper/getFeatures";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import { getInstance } from "../lib/Sentry";
import "../lib/i18n";
import getInitialCurrentUser from "../InitialCurrentUser/getFromAPI";
import makeStore from "../_app/makeStore";
import type { GlobalState } from "../GlobalState";
import type { Action } from "../_app/Action";
import { updateUserSuccessActionCreator } from "../_app/UpdateUserSuccessAction";
import { fetchInitialUserSuccessActionCreator } from "../_app/FetchInitialUserSuccessAction";
import { fetchUnreadCountsSuccessActionCreator } from "../_app/FetchUnreadCountsSuccessAction";
import "../_app/style.scss";

const GLOBAL_FEATURES: Feature[] = [
  Feature.CYBER_SALE_2024,
  Feature.HOLIDAY_GIFTS_2024,
  Feature.WEB_IMPROVED_IA,
];

// Initializes Sentry to catch unhandled exceptions.
getInstance();

type GlobalData = {
  [PUBLIC_CONFIG_KEY]: PublicConfig;
  [CATEGORIES_RESPONSE_CACHE_KEY]: CategoryResponseData;
};

export type GlobalAppProps = {
  globalData: GlobalData;
};

const Storefront = ({
  Component,
  pageProps,
  globalData,
}: AppProps & GlobalAppProps) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { data: user } = useSWR("initialCurrentUser", getInitialCurrentUser);
  const { data: unreadCounts } = useSWR(
    () => (user ? "unreadCounts" : null),
    getUnreadCounts,
  );

  const initialData = pageProps.initialData || {};

  useEffect(() => {
    if (window.RISKX) window.RISKX.go(router.asPath);
  }, [router.asPath]);

  useEffect(() => {
    dispatch(updateUserSuccessActionCreator(user));
    dispatch(fetchInitialUserSuccessActionCreator());
  }, [dispatch, user]);

  useEffect(() => {
    if (unreadCounts) {
      dispatch(fetchUnreadCountsSuccessActionCreator(unreadCounts));
    }
  }, [dispatch, unreadCounts]);

  useEffect(() => {
    return requestWatcher.subscribe((requestKey) => {
      const flashManager = FlashManager.getInstance();
      flashManager.notice(`${requestKey}: Too many requests!`);
    });
  }, []);

  useEffect(() => {
    if (!window.PUBLIC_CONFIG) {
      window.PUBLIC_CONFIG = underscorify(globalData[PUBLIC_CONFIG_KEY]);
    }
  }, []);

  const initialAndGlobalData = {
    ...globalData,
    ...initialData,
  };

  return (
    <AuthenticationProvider>
      <PhoneVerificationProvider>
        <AnalyticsProvider>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <InitialDataProvider data={initialAndGlobalData}>
            <Component {...pageProps} />
          </InitialDataProvider>
          <Script
            type="text/plain"
            className="optanon-category-C0003"
            id="lux-inline"
          >
            {
              'LUX=function(){function n(){return Date.now?Date.now():+new Date}var r,e=n(),t=window.performance||{},a=t.timing||{navigationStart:(null===(r=window.LUX)||void 0===r?void 0:r.ns)||e};function o(){return t.now?t.now():n()-a.navigationStart}LUX={ac:[],addData:function(n,r){return LUX.cmd(["addData",n,r])},cmd:function(n){return LUX.ac.push(n)},init:function(){return LUX.cmd(["init"])},mark:function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.mark)return t.mark.apply(t,n);var e=n[0],a=n[1]||{};void 0===a.startTime&&(a.startTime=o());LUX.cmd(["mark",e,a])},markLoadTime:function(){return LUX.cmd(["markLoadTime",o()])},measure:function(){for(var n=[],r=0;r<arguments.length;r++)n[r]=arguments[r];if(t.measure)return t.measure.apply(t,n);var e,a=n[0],i=n[1],u=n[2];e="object"==typeof i?n[1]:{start:i,end:u};e.duration||e.end||(e.end=o());LUX.cmd(["measure",a,e])},send:function(){return LUX.cmd(["send"])},ns:e};var i=LUX;if(window.LUX_ae=[],window.addEventListener("error",(function(n){window.LUX_ae.push(n)})),window.LUX_al=[],"function"==typeof PerformanceObserver&&"function"==typeof PerformanceLongTaskTiming){var u=new PerformanceObserver((function(n){for(var r=n.getEntries(),e=0;e<r.length;e++)window.LUX_al.push(r[e])}));try{u.observe({type:"longtask"})}catch(n){}}return i}();'
            }
          </Script>
          <Script
            id="lux"
            type="text/plain"
            className="optanon-category-C0003"
            src="https://cdn.speedcurve.com/js/lux.js?id=286727734"
            crossOrigin="anonymous"
          />
        </AnalyticsProvider>
      </PhoneVerificationProvider>
    </AuthenticationProvider>
  );
};

Storefront.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context);

  // GLOBAL DATA -- NEEDED FOR EVERY PAGE
  const getFeatureFlagStates: () => Promise<FlipperStates> = async () =>
    GLOBAL_FEATURES.length > 0
      ? getFeatures(
          { keys: GLOBAL_FEATURES },
          {
            headers: context.ctx?.req?.headers,
          },
        )
      : {};

  const [publicConfig, categoriesResponse, features] = await Promise.all([
    getConfig(),
    getCategories(),
    getFeatureFlagStates(),
  ]);

  const featureFlags = Object.entries(features).reduce<{
    [key: string]: { [key: string]: { enabled: boolean } };
  }>((acc, [key, value]) => {
    const flagKey = `useFeatureFlag-${key}`;
    acc[flagKey] = { [key]: value };
    return acc;
  }, {});

  const globalData: GlobalData = {
    [PUBLIC_CONFIG_KEY]: publicConfig,
    [CATEGORIES_RESPONSE_CACHE_KEY]: categoriesResponse,
    ...featureFlags,
  };
  return {
    ...ctx,
    globalData,
  };
};

const config = {
  debug: false,
};
const wrapper = createWrapper<GlobalState, Action>(makeStore, config);
// @ts-expect-error ts-migrate(2558) FIXME: Expected 0 type arguments, but got 1.
const StorefrontWithRedux = wrapper.withRedux<AppProps>(Storefront);
export default StorefrontWithRedux;
