import type { ListingIndexType, CollectibleIndexType } from "./IndexType";
import matchSoldPage from "../matchSoldPage";

const listingIndexToSort: Record<string, ListingIndexType> = {
  Listing_by_followers: "followerno desc",
  Listing_by_heat: "heat desc",
  Listing_by_date_added: "created_at desc",
  Listing_by_low_price: "price asc",
  Listing_by_high_price: "price desc",
  Listing_sold_by_high_price: "sold sold_price desc",
  Listing_sold_by_low_price: "sold sold_price asc",
  Listing_sold_by_date_added: "sold sold_at desc",
};

export const collectionIndexToSort: Record<string, CollectibleIndexType> = {
  Listing_collectible: "rank asc",
};

const fallbackSort = (): ListingIndexType =>
  matchSoldPage() ? "sold sold_at desc" : "price_updated_at desc";

const nameToIndexSort = (
  name: string,
): ListingIndexType | CollectibleIndexType =>
  listingIndexToSort[name] || collectionIndexToSort[name] || fallbackSort();

export default nameToIndexSort;
